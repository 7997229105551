import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '../../../utils/user.model';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { NgHttpCachingHeaders } from 'ng-http-caching';
import { NotificationResponse, NotificationType } from '../models/notification';
import { PaginationResponse } from 'src/app/utils/pagination.response';

const BASE_URL = environment.AUTH_API_URL;
const NOTIF_URL = environment.NOTIF_API_URL;
const RESUME_URL = environment.RESUME_URL;

@Injectable({
   providedIn: 'root'
})
export class ProfileService {
   private userDataSource: BehaviorSubject<any | null> = new BehaviorSubject(null);
   userData = this.userDataSource.asObservable();

   private notificationsCountModifySource: BehaviorSubject<number> = new BehaviorSubject(0);
   notificationsCountModify = this.notificationsCountModifySource.asObservable();

   followInfo = signal<{
      userId: number | undefined;
      Following: number;
      Followers: number;
      invites: number;
      status: boolean;
   }>({
      userId: undefined,
      Following: 0,
      Followers: 0,
      invites: 0,
      status: false
   });

   constructor(private http: HttpClient) {}

   getUserInfo(): Observable<User> {
      return this.http.get<User>(`${BASE_URL}accounts/getregisterinfo`);
   }

   getuserInfoByUsername(username: string): Observable<User> {
      return this.http.get<User>(`${BASE_URL}accounts/getprofilebyusername/${username}`);
   }

   getUserInfoById(userId: number): Observable<User> {
      return this.http.get<User>(`${BASE_URL}accounts/profile/${userId}`, {
         headers: {
            [NgHttpCachingHeaders.ALLOW_CACHE]: '1',
            [NgHttpCachingHeaders.TAG]: 'profile'
         }
      });
   }

   getUserInfoByIdPromise(userId: number): Promise<User> {
      return new Promise<User>((resolve, reject) => {
         return this.http
            .get<User>(`${BASE_URL}accounts/profile/${userId}`, {
               headers: {
                  [NgHttpCachingHeaders.ALLOW_CACHE]: '1',
                  [NgHttpCachingHeaders.TAG]: 'profile'
               }
            })
            .pipe(take(1))
            .subscribe({
               next: (user: User) => {
                  if (user) resolve(user);
               },
               complete: () => {}
            });
      });
   }

   updateUserInfo(user: any, ownerId?: number | null): Observable<User> {
      const queryParam = ownerId ? `?ownerId=${ownerId}` : '';
      return this.http.put<User>(`${BASE_URL}accounts/profile${queryParam}`, user);
   }

   getGeolocationByLatLng(position: { lat: number | undefined; lng: number | undefined }) {
      // return this.http.get(`https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&sensor=false&key=${environment.GOOGLE_API_KEY}`)
      return this.http.get(
         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&sensor=false&key=${environment.GOOGLE_API_KEY}`
      );
   }

   updateProfilePhoto(data: FormData, ownerId?: number | null) {
      const queryParam = ownerId ? `?ownerId=${ownerId}` : '';
      return this.http.post(`${BASE_URL}accounts/uploadprofilephoto${queryParam}`, data);
   }

   updateCoverPhoto(data: FormData, ownerId?: number | null) {
      const queryParam = ownerId ? `?ownerId=${ownerId}` : '';
      return this.http.post(`${BASE_URL}accounts/uploadcoverimage${queryParam}`, data);
   }

   searchUserByUsername(username: string): Observable<User[]> {
      return this.http.get<User[]>(`${BASE_URL}accounts/usersearch?username=${username}`);
   }

   searchUsersByFull(text: string): Observable<User[]> {
      return this.http.get<User[]>(`${BASE_URL}accounts/userfullsearch?username=${text}`);
   }

   getInvitedListByUserId(
      userId: number,
      pageNumber: number,
      pageSize: number = 20
   ): Observable<PaginationResponse<User>> {
      return <Observable<PaginationResponse<User>>>(
         this.http.get(
            `${BASE_URL}accounts/invited-users?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
         )
      );
   }

   getAllNotificationsByPage(
      page: number,
      items: number = 8,
      types: NotificationType[]
   ): Observable<NotificationResponse> {
      const params = types
         .reduce((acc, type) => {
            return acc.append('types', type);
         }, new HttpParams())
         .append('pageNumber', page)
         .append('pageSize', items);
      return this.http.get<NotificationResponse>(`${NOTIF_URL}notifications/getnotificbyuserid`, { params: params });
   }

   getNotificationsCount(): Observable<{ count: number }> {
      return this.http.get<{ count: number }>(`${NOTIF_URL}notifications/unseennoti`);
   }

   setNotificationsCountModify(count: number) {
      if (count) this.notificationsCountModifySource.next(count);
   }

   sendNotification(data: any): Observable<any> {
      return this.http.post<any>(`${NOTIF_URL}notifications/sendnotif`, data);
   }

   updateUnseenNotificationList(unseenIds: number[]): Observable<any> {
      return this.http.post<any>(`${NOTIF_URL}notifications/notifseen`, unseenIds);
   }

   sendFile(data: any): Observable<any> {
      return <Observable<any>>this.http.post(`${RESUME_URL}uploads`, data);
   }
}
